import React, { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [num, setNum] = useState(2);
  const [mainNum, setMainNum] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setNum((num) => {
        if (num === 4) {
          return 2;
        }
        return num + 1;
      });
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setMainNum((num) => {
        if (num === 1 || num === 2 || num === 3) {
          return 5;
        }
        if (num === 33) {
          return 1;
        }
        return num + 1;
      });
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>W</h1>
        <span className="heart"> ❤️ </span>
        <h1>J</h1>
      </header>
      <div className="content">
        <p>{`너 때문에 웃어`}</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          padding: "5rem",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="jiggle">
          <img src="/beer.png" alt="Beer" />
        </div>
        <div>
          <img
            src={`/${num}.jpeg`}
            style={{ height: "300px", width: "auto" }}
          />
        </div>
        ❤️
        <div>
          <img
            src={`/${mainNum}.${
              [21, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33].includes(mainNum)
                ? `png`
                : `jpeg`
            }`}
            style={{ height: "300px", width: "auto" }}
          />
        </div>
        <div className="jiggleReverse">
          <img src="/beer.png" alt="Beer" />
        </div>
      </div>
      <footer className="footer">Forever and always. -W</footer>
    </div>
  );
}

export default App;
